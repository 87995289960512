@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Proxima Nova", system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Proxima Nova", system-ui, sans-serif;
}
