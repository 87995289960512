@media (min-width:320px)  { 

}
@media  (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ }
@media (max-width:750px)  { 
    .Modal{
        width:90% !important;
        height: max-content !important;

    }
    .ModalImage{
        max-height: max-content !important;
        overflow: hidden;
        flex-direction: column;
        position: relative;
    }
    .ModalImage img{
        object-fit: cover;
        width: 100% !important;
        height: auto !important;

    }
    .headlineModal div{
        width: 100% ;
        flex-direction: row;
    }

    .headlineModal p {
        width: 100% !important;
    height: max-content !important;
    }

    .closeButton{
        position: absolute;
        right:15px;
        top:20px;
        background-color: #fff;
    }

    

 }
 @media screen and (min-width:750px) and (max-width:900px)  {
    .Modal{
        width:700px !important;
        height: 400px!important;

    }
    .ModalImage{
        max-height: 400px!important;
        overflow: hidden;

    }
    .ModalImage img{
        object-fit: cover;
        width: 250px !important;
        height: 400px !important;

    }

}

  .card3 {
    &:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      border: 1px solid #cccccc;
      background-color: white;
    }  
    &:hover:before {
      transform: scale(2.15);
    }
  }




  /* HTML: <div class="loader"></div> */
.loader {
  width: 40px;
  aspect-ratio: 1;
  --c: linear-gradient(#000 0 0);
  --r1: radial-gradient(farthest-side at bottom,#000 93%,#0000);
  --r2: radial-gradient(farthest-side at top   ,#000 93%,#0000);
  background: 
    var(--c) ,var(--r1),var(--r2),
    var(--c) ,var(--r1),var(--r2),
    var(--c) ,var(--r1),var(--r2);
  background-repeat: no-repeat;
  animation: l2 1s infinite alternate;
}
@keyframes l2 {
  0%,25% {
    background-size: 8px 0,8px 4px,8px 4px,8px 0,8px 4px,8px 4px,8px 0,8px 4px,8px 4px;
    background-position: 0 50%,0 calc(50% - 2px),0 calc(50% + 2px),50% 50%,50% calc(50% - 2px),50% calc(50% + 2px),100% 50%,100% calc(50% - 2px),100% calc(50% + 2px);
 }
 50% {
    background-size: 8px 100%,8px 4px,8px 4px,8px 0,8px 4px,8px 4px,8px 0,8px 4px,8px 4px;
    background-position: 0 50%,0 calc(0% - 2px),0 calc(100% + 2px),50% 50%,50% calc(50% - 2px),50% calc(50% + 2px),100% 50%,100% calc(50% - 2px),100% calc(50% + 2px);
 }
 75% {
    background-size: 8px 100%,8px 4px,8px 4px,8px 100%,8px 4px,8px 4px,8px 0,8px 4px,8px 4px;
    background-position: 0 50%,0 calc(0% - 2px),0 calc(100% + 2px),50% 50%,50% calc(0% - 2px),50% calc(100% + 2px),100% 50%,100% calc(50% - 2px),100% calc(50% + 2px);
 }
 95%,100% {
    background-size: 8px 100%,8px 4px, 8px 4px,8px 100%,8px 4px,8px 4px,8px 100%,8px 4px,8px 4px;
    background-position: 0 50%,0 calc(0% - 2px),0 calc(100% + 2px),50% 50%,50% calc(0% - 2px),50% calc(100% + 2px),100% 50%,100% calc(0% - 2px),100% calc(100% + 2px);
 }
}